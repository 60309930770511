import React from "react";
import { graphql, Link, navigate } from "gatsby";
import {
  fetchAuthenticatedContent,
  AuthContext,
} from "@parallelpublicworks/understory-auth";
import { Register } from "../components";
import { SiteContext } from "../layouts/Main";
import { CircularProgress } from "@material-ui/core";

async function patchUser(user_id, dispatch, { attributes, relationships }) {
  if (dispatch && user_id && attributes) {
    let body = { data: { type: "user--user", id: user_id, attributes, relationships } };
    return fetchAuthenticatedContent(
      dispatch,
      `user/user/${user_id}`,
      "PATCH",
      body
    ).then((resp) => {
      if (resp) {
        dispatch({
          type: "CHANGE_ENTITY",
          data: resp.data,
          save: "nocache",
        });
        return resp.data;
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
}

function User({ data }) {
  const { userData, setUserData, privateRoute } = React.useContext(SiteContext);
  const authContext = React.useContext(AuthContext);
  const [{ isAuthenticated }, dispatch] = authContext;
  const [requestSent, setRequestSent] = React.useState(null);

  if (!isAuthenticated && typeof window !== "undefined") {
    navigate(privateRoute);
  }

  const onSubmit = async (form_data, setSubmitting, setError) => {
    setSubmitting(true);
    setError(null);
    try {
      let patching = await patchUser(userData.id, dispatch, form_data);
      if (patching) {
        setUserData({ ...patching });
        setRequestSent(
          <>
            <strong>Your profile was updated</strong>{" "}
            <Link to={privateRoute}>Return to dashboard</Link>
          </>
        );
        setSubmitting(false);
      } else {
        throw new Error("We could not update your profile");
      }
    } catch (err) {
      setSubmitting(false);
      setError(err.message);
    }
  };

  if (userData === null) return <CircularProgress />;
  return (
    <>
      <Register
        requestSent={requestSent}
        title="Update Profile"
        onSubmit={onSubmit}
        graphqlData={data}
        submitLabel="Save"
        method="PATCH"
      />
    </>
  );
}

export default User;
export const query = graphql`
  {
    field_agency: allEntityRef(
      filter: { type: { eq: "node--agency" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          nid
          title
          type
        }
      }
    }
    field_work_group: allEntityRef(
      filter: { type: { eq: "node--work_group" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          nid
          title
          type
        }
      }
    }
    field_expertise: allTermRef(
      filter: { type: { eq: "taxonomy_term--expertise" } }
      sort: { fields: title }
    ) {
      edges {
        node {
          id
          tid
          title
          type
        }
      }
    }
  }
`;
